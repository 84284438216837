<template>
  <div class="form-group taxes-selector">
    <label class="form-control-label"> {{ label }} </label>
    <div
      v-for="(transaction, key) in transactionsModel"
      :key="key"
      class="taxes-wrapper"
    >
      <div class="form-group-item row name">
        <div class="col-6">
          <base-input
              :label="`${$t('COMMON.AMOUNT')} (*)`"
              :placeholder="$t('COMMON.AMOUNT')"
              v-model="transaction.amount"
              type="number"
              @change="transactionsChanged"
          >
          </base-input>
        </div>
        <div class="col-6">
          <base-input
              :label="`${$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')} (*)`"
              :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
          >
            <el-select
                :clearable="true"
                class="select-primary pagination-select"
                :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
                v-model="transaction.method_payment"
                @change="transactionsChanged"
                style="margin-top: 0"
            >
              <el-option
                  class="select-primary"
                  v-for="(item, key) in PAYMENT_METHODS"
                  :key="key"
                  :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
                  :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiError?.[`transactions.${key}.method_payment`]"/>
        </div>
      </div>

      <div class="remove-taxes-button">
        <base-button
          type="button"
          class="form-control btn btn-danger"
          :disabled="transactionsModel?.length === 1"
          @click.prevent="() => removeTransaction(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button :disabled="currentTotal >= (parseFloat(property?.total).toFixed(2))" type="button" class="btn add" @click.prevent="addTransaction">
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import {AUCTION_PAYMENTS} from "@/constants/auctions";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";

import {TRANSACTION_TYPE_PAYMENT, TRANSACTION_TYPE_REFUND, TRANSACTION_TYPES,} from "@/constants/properties";

export default {
  name: "property-payment-inputs",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    ValidationError,
  },

  props: {
    transactions: {
      type: Array,
      default: null,
      description: "Batch numbers array",
    },
    property: {
      type: [Object],
      default: null,
      description: "Property",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
    errors: {
      type: [Array, Object],
      default: {},
      description: "Array of api error of field",
    },
  },

  data() {
    return {
      transactionsModel: this.transactions?.length ? this.transactions.filter(transaction => transaction.transaction_type === TRANSACTION_TYPE_PAYMENT) : [],
      PAYMENT_METHODS: AUCTION_PAYMENTS,
      currentTotal: parseFloat(this.property?.total??0)?.toFixed(2),
      apiError: [],
    };
  },

  setup() {},

  mounted() {
    if (!this.transactionsModel?.length) {
      this.addTransaction()
    }
  },

  created() {},

  methods: {
    async addTransaction() {
      const amount = parseFloat(this.property?.total) - parseFloat(this.currentTotal)
      this.transactionsModel.push({
        amount: amount?.toFixed(2),
        transaction_type: TRANSACTION_TYPE_PAYMENT,
        method_payment: null,
      });
      this.transactionsChanged();
    },
    removeTransaction(key) {
      this.transactionsModel = this.transactionsModel.filter(
        (_, idx) => idx !== key);
      this.transactionsChanged();
    },

    updateCurrentTotalAmount() {
      let amount = 0;
      if (this.transactionsModel?.length) {
        for (let i = 0; i < this.transactions.length; i++) {
          amount += parseFloat(this.transactionsModel?.[i]?.amount ? parseFloat(this.transactionsModel?.[i]?.amount)?.toFixed(2) : 0)
        }
      }

      if (this.transactionsModel?.length) {
        this.currentTotal = amount;
      }
    },
    transactionsChanged() {
      const transactionsData = this.transactionsModel.map((item) => {
        return item;
      });
      this.updateCurrentTotalAmount()
      this.$emit("transactionsChanged", transactionsData);
    },
  },

  watch: {
    transactions(transactions) {
      this.transactionsModel = transactions;
      this.transactionsModel = transactions.filter(transaction => transaction.transaction_type === TRANSACTION_TYPE_PAYMENT);
      if (!this.transactionsModel?.length) {
        this.addTransaction()
        this.updateCurrentTotalAmount()
      }
    },
    property(property) {
      this.currentTotal = parseFloat(property?.total??0)?.toFixed(2)
    },
    transactionsModel(values) {
      this.updateCurrentTotalAmount()
    },
    errors(values) {
      this.apiError = values
    }
  },
};
</script>

<style lang="scss">
.form-group {
  &.taxes-selector {
    > label {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
    }

    .remove-taxes-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 43px;
          height: 43px;
          border-radius: 8px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }

    button {
      &.add {
        width: 43px;
        height: 43px;
        border-radius: 8px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  .taxes-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
