<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-3">
            <h3 class="mb-0">{{ $t("PROPERTIES.PROPERTIES_LIST") }}</h3>
          </div>
          <div class="col-9 text-right">
            <base-dropdown
              title-classes="btn dropdown-button export"
              menu-on-bottom
              :has-toggle="false"
            >
              <template slot="title">
                <base-button>
                  <span class="text">
                    {{ $t("COMMON.EXPORT") }}
                  </span>
                  <span class="icon">
                    <i class="far fa-chevron-down"></i>
                  </span>
                </base-button>
              </template>
              <base-button
                v-if="$currentUserCan($permissions.PERM_EXPORTS_PROPERTIES)"
                @click="triggerDataExport(false)"
              >
                <span class="btn-inner--icon">
                  <i class="far fa-arrow-alt-circle-up"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("PROPERTIES.EXPORT_ALL") }}
                </span>
              </base-button>
              <base-button
                v-if="$currentUserCan($permissions.PERM_EXPORTS_PROPERTIES)"
                @click="triggerDataExport(true)"
              >
                <span class="btn-inner--icon">
                  <i class="far fa-arrow-alt-circle-up"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("PROPERTIES.EXPORT_CONFIRMED") }}
                </span>
              </base-button>

              <base-button @click="exportPublicNotices()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-excel"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.PUBLIC_NOTICES_EXCEL")
                }}</span>
              </base-button>

              <base-button @click="exportPdfPublicNotices()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-pdf"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.PUBLIC_NOTICES_PDF")
                }}</span>
              </base-button>

              <base-button @click="exportSoldProperties()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-excel"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.SOLD_PROPERTIES")
                }}</span>
              </base-button>

              <base-button @click="exportUnsoldProperties()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-pdf"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.UNSOLD_PROPERTIES")
                }}</span>
              </base-button>

              <base-button @click="exportSuperiorCourtProperties()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-excel"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.SUPERIOR_COURT_PROPERTIES")
                }}</span>
              </base-button>

              <base-button @click="exportPaidProperties()">
                <span class="btn-inner--icon">
                  <i class="fa fa-file-excel"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.PAID_PROPERTIES")
                }}</span>
              </base-button>

              <base-button
                :disabled="selectedProperties.length !== 0"
                @click="openDetailsModal()"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-print"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.DIRECT_MAIL")
                }}</span>
              </base-button>
              <base-button
                :disabled="selectedProperties.length === 0"
                @click="openDetailsModal()"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-print"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.SELECTED_DIRECT_MAIL")
                }}</span>
              </base-button>
            </base-dropdown>
            <!--<base-button
              v-if="$currentUserCan($permissions.PERM_EXPORTS_PROPERTIES)"
              class="elite-button"
              icon
              type="success"
              size="sm"
              @click="triggerDataExport"
            >
              <span class="btn-inner--icon">
                <i class="far fa-arrow-alt-circle-down"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("PROPERTIES.EXPORT_EXCEL")
              }}</span>
            </base-button>
            <el-tooltip
              :content="$t('PROPERTIES.PRINT_ALL_TOOLTIP')"
              placement="top"
            >
              <base-button
                class="elite-button add"
                icon
                size="sm"
                @click="print"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-print"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.PRINT_ALL")
                }}</span>
              </base-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('PROPERTIES.PRINT_SELECTION_TOOLTIP')"
              placement="top"
            >
              <base-button
                class="elite-button add"
                icon
                :disabled="selectedProperties.length === 0"
                size="sm"
                @click="printSelection"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-print"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("PROPERTIES.PRINT_SELECTION")
                }}</span>
              </base-button>
            </el-tooltip>-->
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPropertyCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_PROPERTIES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("PROPERTIES.ADD_PROPERTY")
              }}</span>
            </base-button>
            <import-properties-component v-if="$currentUserCan($permissions.PERM_SYNC_PROPERTIES)" @syncSuccess="triggerGetList"></import-properties-component>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PROPERTIES)"
              :objectType="'properties'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <property-list-table
        @onViewProperty="openPropertyViewModal"
        @onEditProperty="openPropertyEditModal"
        @onDeleteProperty="deleteProperty"
        @onSelectedProperties="getSelectedProperties"
        :isSelectable="displayPrintButtons"
        :key="renderKey * 100"
        ref="propertyList"
        :onlyConfirmed="onlyConfirmed"
      />

      <div
        v-if="isViewPropertyModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPropertyModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PROPERTY_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PROPERTIES.VIEW_PROPERTY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <el-tooltip
                v-if="
                  !openProperty?.auction_is_closed ||
                  $currentUserCan(
                    $permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES
                  )
                "
                :content="$t('PROPERTIES.CHANGE_VISIBILITY')"
                placement="top"
              >
                <base-button
                  :type="openProperty?.active ? 'success' : 'danger'"
                  icon
                  size="sm"
                  @click="enableOrDisableProperty(openProperty)"
                  v-if="
                    $currentUserCan(
                      $permissions.PERM_TOGGLE_ACTIVATION_PROPERTIES
                    ) &&
                    $currentUserCan(
                      $permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES
                    )
                  "
                >
                  <template v-if="!openProperty.active">
                    <i class="fal fa-times"></i>
                  </template>
                  <template v-else>
                    <i class="fal fa-check-circle"></i>
                  </template>
                </base-button>
              </el-tooltip>
              <el-tooltip :content="$t('COMMON.NOTIFICATIONS')" placement="top">
                <notification-subscription
                  v-if="openProperty"
                  :objectType="'properties'"
                  :objectId="openProperty.id"
                  :events="{
                    UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                    DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                  }"
                />
              </el-tooltip>
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <!--
                <button
                  class="edit"
                  @click="enableOrDisableProperty(openProperty)"
                >
                  <template v-if="openProperty.active">
                    <i class="fal fa-times"></i>
                    <span>{{ $t("COMMON.DISABLE") }}</span>
                  </template>
                  <template v-else>
                    <i class="fal fa-check-circle"></i>
                    <span>{{ $t("COMMON.ENABLE") }}</span>
                  </template>
                </button>
-->
                <button
                  v-if="
                    !openProperty?.auction_is_closed ||
                    $currentUserCan(
                      $permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES
                    )
                  "
                  class="edit"
                  @click="openPropertyEditModal(openProperty)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="deleteProperty(openProperty)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePropertyModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-property-page
              @propertyChange="onPropertyChange"
              v-if="openProperty"
              :propertyId="openProperty.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPropertyModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPropertyModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PROPERTY'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PROPERTIES.EDIT_PROPERTY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openPropertyViewModal(openProperty)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePropertyModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-property-page
              v-if="openProperty"
              :propertyId="openProperty.id"
              @onViewProperty="openPropertyViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPropertyModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPropertyModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PROPERTY'">
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PROPERTIES.ADD_PROPERTY") }}
            </h1>
            <button class="close" @click="closePropertyModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-property-page @onViewProperty="openPropertyViewModal" />
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showModal"
      :show="showModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-center font-weight-700 text-uppercase w-100"
        >
          {{ $t("PROPERTIES.PRINT_MODAL_HEADER") }}
        </div>
      </template>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.AUCTION')}(*)`"
            :placeholder="$t('COMMON.AUCTION')"
          >
            <auction-selector
              :placeholder="$t('COMMON.AUCTION')"
              :auction="details.auction"
              :filterable="true"
              :showAll="false"
              :filterOrganization="items[0]?.organization?.id"
              @auctionChanged="
                (auctionId) => {
                  details.auction = auctionId;
                }
              "
            />
          </base-input>
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.CITY')} (*)`"
            :placeholder="$t('COMMON.CITY')"
            v-model="details.city"
          >
          </base-input>
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.DATE')} (*)`"
            :placeholder="$t('COMMON.DATE')"
            v-model="details.date"
          >
            <flat-picker
              :placeholder="$t('COMMON.DATE')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="details.date"
            >
            </flat-picker>
          </base-input>
        </div>
        
      </form>
      <template slot="footer">
        <button
          v-if="selectedProperties.length !== 0"
          type="submit"
          class="btn btn-primary"
          @click="printSelection"
          :disabled="details.city === '' || details.date === '' || details.auction === ''"
        >
          {{ $t("PROPERTIES.PRINT") }}
        </button>

        <button
          v-else
          type="submit"
          class="btn btn-primary"
          @click="print"
          :disabled="details.city === '' || details.date === '' || details.auction === ''"
        >
          {{ $t("PROPERTIES.PRINT") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showModal = false; details.auction = null"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button, Option, Select } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PropertyListTable from "./partials/PropertyListTable.vue";
import EditPropertyPage from "./components/EditPropertyComponent.vue";
import AddPropertyPage from "./components/AddPropertyComponent.vue";
import ViewPropertyPage from "./components/ViewPropertyComponent.vue";
import ImportPropertiesComponent from "./components/ImportPropertiesComponent.vue";
import AuctionSelector from "../../../../components/AuctionSelector.vue";
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    PropertyListTable,
    NotificationSubscription,
    AuctionSelector,
    EditPropertyPage,
    AddPropertyPage,
    ViewPropertyPage,
    ImportPropertiesComponent,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPropertyModalOpened = false;
    let isEditPropertyModalOpened = false;
    let isAddPropertyModalOpened = false;
    let openProperty = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPropertyModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPropertyModalOpened = true;
      }
      openProperty = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPropertyModalOpened = true;
    }
    return {
      isViewPropertyModalOpened: isViewPropertyModalOpened,
      isEditPropertyModalOpened: isEditPropertyModalOpened,
      isAddPropertyModalOpened: isAddPropertyModalOpened,
      openProperty: openProperty,
      renderKey: 1,
      onlyConfirmed: false,
      selectedProperties: [],
      displayPrintButtons: true,
      showModal: false,
      details: {
        city: "",
        date: "",
        auction: "",
        timezone: "",
      },
      items: "",
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },

  methods: {
    openDetailsModal() {
      this.items = this.$store.getters["properties/list"];
      this.items.length &&
        (this.details.city = this.items[0].organization.billing_city);
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      this.details.date = `${year}-${month}-${day}`;
      this.details.timezone = this.userTimezone;
      this.showModal = true;
    },

    openPropertyCreateModal() {
      this.closePropertyModal();
      this.isAddPropertyModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Properties",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    async triggerDataExport(value) {
      this.$root.$emit("handleDataExport", {
        onlyConfirmed: value,
      });
    },

    triggerGetList() {
      this.$refs.propertyList.getList();
    },

    onPropertyChange(property) {
      this.openProperty = property;
    },

    openPropertyViewModal(property, reRender = false) {
      this.closePropertyModal();
      this.openProperty = property;
      this.isViewPropertyModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Properties",
          query: { id: this.openProperty.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openPropertyEditModal(property) {
      this.closePropertyModal();
      this.openProperty = property;
      this.isEditPropertyModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Properties",
          query: { id: this.openProperty.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closePropertyModal() {
      this.isAddPropertyModalOpened = false;
      this.isViewPropertyModalOpened = false;
      this.isEditPropertyModalOpened = false;
      this.openProperty = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Properties",
          query: {},
        }).href
      );
    },

    async deleteProperty(property) {
      const confirmation = await swal.fire({
        title: this.$t("PROPERTIES.DELETE_THIS_PROPERTY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("properties/destroy", property.id);
          this.renderKey++;
          this.closePropertyModal();
          this.$notify({
            type: "success",
            message: this.$t("PROPERTIES.PROPERTY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async enableOrDisableProperty(property) {
      const confirmation = await swal.fire({
        title: property?.active
          ? this.$t("PROPERTIES.DISABLE_THIS_PROPERTY")
          : this.$t("PROPERTIES.ENABLE_THIS_PROPERTY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      try {
        if (confirmation.value === true) {
          if (property?.active !== undefined) {
            if (property.active) {
              await this.$store.dispatch("properties/deactivate", property.id);
              this.openProperty.active = false;
              this.renderKey++;
              this.$notify({
                type: "success",
                message: this.$t("PROPERTIES.PROPERTY_DISABLE"),
              });
            } else {
              await this.$store.dispatch("properties/activate", property.id);
              this.openProperty.active = true;
              this.renderKey++;
              this.$notify({
                type: "success",
                message: this.$t("PROPERTIES.PROPERTY_ENABLE"),
              });
            }
          }
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async exportSuperiorCourtProperties() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const excelResponse = await this.$store.dispatch(
          "properties/exportSuperiorCourtProperties"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.SUPERIOR_COURT_PROPERTIES_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_SOLD_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async exportPaidProperties() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const excelResponse = await this.$store.dispatch(
          "properties/exportPaidProperties"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.PAID_PROPERTIES_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PAID_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async exportPublicNotices() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const excelResponse = await this.$store.dispatch(
          "properties/exportPublicNotices"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.PUBLIC_NOTICE_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async exportPdfPublicNotices() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const pdfResponse = await this.$store.dispatch(
          "properties/exportPdfPublicNotices"
        );

        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.PUBLIC_NOTICE_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async exportSoldProperties() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const excelResponse = await this.$store.dispatch(
          "properties/exportSoldProperties"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.SOLD_PROPERTIES_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_SOLD_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async exportUnsoldProperties() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const pdfResponse = await this.$store.dispatch(
          "properties/exportUnsoldProperties",
          this.details
        );
        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.UNSOLD_PROPERTIES_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const pdfResponse = await this.$store.dispatch(
          "properties/printPdfLetters",
          this.details
        );
        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.LETTERS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        const excelResponse = await this.$store.dispatch(
          "properties/exportThumbnails",
          this.details
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.THUMBNAILS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);

        this.details.auction = "";
        swal.close();
        this.showModal = false;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else if (error?.response?.status === 400) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.ALL_FIELDS_REQUIRED"),
          });
        } else if (error?.response?.status === 403) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.TEMPLATES_REQUIRED"),
          });
        }
         else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        // this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async getSelectedProperties(properties) {
      this.selectedProperties = properties;
    },
    
    async printSelection() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const data = {
          selectedProperties: this.selectedProperties,
          details: this.details,
        };
        const pdfResponse = await this.$store.dispatch(
          "properties/printLettersForSelection",
          data
        );
        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.LETTERS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        const excelResponse = await this.$store.dispatch(
          "properties/exportThumbnailsForSelection",
          data
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.THUMBNAILS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);

        this.details.auction = "";
        swal.close();
        this.showModal = false;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else if (error?.response?.status === 400) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.ALL_FIELDS_REQUIRED"),
          });
        } else if (error?.response?.status === 403) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.TEMPLATES_REQUIRED"),
          });
        }
         else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        swal.close();
      }
    },
  },
};
</script>
<style>
.modal .modal-content .modal-footer button:disabled {
  opacity: .7;
  cursor: not-allowed;
}
</style>
